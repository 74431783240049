import { render, staticRenderFns } from "./AuthenticateView.vue?vue&type=template&id=89d0aa86&"
import script from "./AuthenticateView.vue?vue&type=script&lang=ts&"
export * from "./AuthenticateView.vue?vue&type=script&lang=ts&"


/* normalize component */
import normalizer from "!../../../node_modules/vue-loader/lib/runtime/componentNormalizer.js"
var component = normalizer(
  script,
  render,
  staticRenderFns,
  false,
  null,
  null,
  null
  
)

export default component.exports