






import { Component, Vue } from 'vue-property-decorator';

import { NoReporterOrganisationMessageCpt } from '@/core/components';

@Component({
  components: {
    NoReporterOrganisationMessageCpt,
  },
})
export default class NoAccessView extends Vue {}
