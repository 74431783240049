








































import { Component } from 'vue-property-decorator';

import AppVue from '@/AppVue.vue';
import { LocaleChangerSelectCpt } from '@/i18n';

@Component({
  components: {
    LocaleChangerSelectCpt,
  },
})
export default class LoginView extends AppVue {
  get helpEmail() {
    return 'mike.hislop@worldrugby.org';
  }

  async loginPhone() {
    return await this.$msal.loginPhone();
  }

  async loginTotp() {
    return await this.$msal.loginTotp();
  }
}
